<template>
  <div class="page">
    <iframe
      id="planningIframe"
      class="bgIframe"
      :src="srcUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
    <div class="toast" v-show="listData.length > 0">
      <div class="toast-content">
        <div class="toast-l">
          <img src="./img/adress.png" alt="" />
        </div>
        <div class="toast-c">
          <div>当前位置</div>
          <div>
            {{
              current.floor
                ? `${current.build},${current.floor}`
                : `${current.build}`
            }}
          </div>
        </div>
        <div class="toast-r">
          <div class="line"></div>
          <div class="exit" @click="exit">
            <img src="./img/out.png" alt="" />
            <div>退出</div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer" v-show="listData.length > 0">
      <div class="footer-flex">
        <div class="warpper-flex">
          <div class="warpper-tip">
            <div class="warpper-tip--flex">
              <img src="./img/location.png" alt="" />
              <span>路线规划</span>
            </div>
            <div class="refresh" @click="refresh">
              <img src="./img/refresh.png" alt="" />
            </div>
          </div>
          <div class="warpper-content">
            <div
              :class="[
                'section-list',
                current.id === item.id && current.desc === item.desc
                  ? 'current'
                  : '',
              ]"
              v-for="(item, index) in listData"
              :key="index"
              @click="choosePlan(item)"
            >
              <div class="list-tip"><span>规划中</span></div>
              <div class="list-address">
                <span>{{ item.desc }}</span>
              </div>
              <!-- <div class="list-distance">{{ "--" }} 米</div> -->
              <div class="list-distance">第 {{ index + 1 }} 步</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stepURL } from "./api";

export default {
  data() {
    return {
      loaded: false,
      srcUrl: "",
      focus: undefined,
      form: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
        id: "14ea426f0f98421cab4f9ec01160c0d2",
      },
      curTab: {},
      current: {},
      listData: [],
      timeId: null,
      id: null,
    };
  },
  created() {
    let { form, curTab, id } = this.$route.query;
    if (id != undefined) {
      this.id = id;
      this.srcUrl = `https://cgzc-dev.zhuneng.cn/d3mdoc/#/iframe/maze?id=${this.id}`;
    }
    if (form) {
      this.form = JSON.parse(form);
      this.curTab = JSON.parse(curTab);
    }
    window.addEventListener("message", this.changeLoaded);
  },
  mounted() {
    this.onLoad();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.changeLoaded);
  },
  computed: {},
  methods: {
    //退出
    exit() {
      this.$router.go(-1);
    },
    //重置
    refresh() {
      let iframe = document.getElementById("planningIframe");
      iframe.contentWindow.postMessage(
        // 重置视角
        { type: "reset" },
        "*"
      );
    },
    changeLoaded(ev) {
      // 监听iframe资源是否加载完成，可以发送postmessage
      console.log(ev);
      if (ev.data && ev.data.type === "loaded") {
        this.loaded = true;

        //进行操作
      }
    },
    showIframe(current) {
      let iframe = document.getElementById("planningIframe").contentWindow;
      const data = {
        start: this.form.start,
        end: this.form.end,
        id: this.id,
      };
      iframe.postMessage(
        // Object,start和end为POI的id，id为导航BIM的资源id
        { type: "maze", data: current || data },
        "*"
      );
    },
    //选择路线
    choosePlan(item) {
      if (item.guide) {
        this.current = item;
        console.log("this.current----->", this.current);
        this.showIframe(this.current);
      }
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(stepURL, {
          params: {
            start: this.form.start, // 开始
            end: this.form.end, // 前往
            priorityFloor: this.curTab.value, // 地下,  地上
          },
        })
        .then(async (res) => {
          if (res.code == 0 && res.data) {
            this.listData = res.data;
            this.current = this.listData.filter((v) => v.guide)[0];
            let iframe =
              document.getElementById("planningIframe").contentWindow;
            if (iframe && this.current.guide) {
              this.$toast.loading({
                duration: 0,
                message: "加载中...",
                forbidClick: true,
                loadingType: "spinner",
              });
              const _this = this;
              const timer = setInterval(() => {
                if (_this.loaded) {
                  this.$toast.clear();
                  console.log("this.current----->", this.current);
                  this.showIframe(_this.current);
                  clearTimeout(timer);
                }
              }, 300);
            }
          } else {
            this.timeId = setTimeout(() => {
              this.$router.go(-1);
            }, 3500);
          }
        });
    },
  },
  destroyed() {
    clearTimeout(this.timeId);
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ededed;
  -webkit-overflow-scrolling: touch; /*看这里*/
  .bgIframe {
    border: 0;
    vertical-align: top;
  }
  .toast {
    position: fixed;
    left: 0;
    top: 5%;
    z-index: 33;
    width: 100%;

    box-sizing: border-box;
    padding: 0 14px;
    &.active {
      animation: fadeIn 0.3s ease 0s 1 normal;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(-100px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .toast-content {
      padding: 36px 34px 34px 42px;
      width: 100%;
      box-sizing: border-box;
      background: #212c49;
      box-shadow: 0px 4px 10px 6px rgba(144, 144, 144, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      .toast-l {
        margin-right: 32px;
        img {
          width: 40px;
          height: 48px;
          vertical-align: middle;
        }
      }
      .toast-c {
        padding-right: 20px;
        box-sizing: border-box;
        flex: 1;
        div {
          width: 100%;
          &:first-child {
            font-size: 28px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
          }
          &:last-child {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      .toast-r {
        display: flex;
        align-items: center;
        .line {
          margin-right: 22px;
          width: 2px;
          height: 52px;
          background-color: rgba(255, 255, 255, 0.43);
        }
        .exit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          img {
            width: 32px;
            height: 32px;
            vertical-align: middle;
            margin: auto auto 4px auto;
          }
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 34px;
        }
      }
    }
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: linear-gradient(180deg, #e9f1fb 0%, #ffffff 100%);
    box-shadow: 0px 4px 10px 6px rgba(218, 218, 218, 0.5);
    border-radius: 16px;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .footer-flex {
      position: relative;
      height: 100%;
      margin: 0 14px;
      background: #ffffff;
      .warpper-flex {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        .warpper-tip {
          display: flex;
          align-items: center;
          justify-content: space-between;

          margin-bottom: 16px;

          .warpper-tip--flex {
            box-shadow: 0px 4px 22px 0px #e5e5e5;
            border-radius: 20px;
            font-size: 24px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            background: #ffffff;
            padding: 20px 14px;
            display: flex;
            align-items: center;
            img {
              height: 30px;
              width: 30px;
              margin-right: 6px;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
          .refresh {
            width: 70px;
            height: 70px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            box-shadow: 0px 4px 10px 6px rgba(218, 218, 218, 0.5);
            border-radius: 16px;
            padding: 20px;
            img {
              width: 32px;
              height: 32px;
            }
          }
        }
        .warpper-content {
          width: 100%;
          min-height: 240px;
          background: linear-gradient(180deg, #e9f1fb 0%, #ffffff 100%);
          box-shadow: 0px 4px 10px 6px rgba(218, 218, 218, 0.5);
          display: flex;
          border-radius: 16px;

          overflow-x: auto;
          overflow-y: hidden;
          .section-list {
            box-sizing: border-box;
            width: 32%;
            min-width: 32%;
            color: rgba(229, 229, 229, 0.5);
            padding: 22px 26px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(0, 0, 0, 0.85);
            // &:not(:first-child) {
            //   margin-left: 2%;
            // }
            .list-tip {
              opacity: 0;
              font-weight: 500;
              height: 34px;
              font-size: 24px;
            }
            .list-address {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              font-size: 26px;
              // margin: 20px 0;
            }
            .list-distance {
              font-size: 24px;
              color: #237dff;
            }
            &.current {
              background: #ffffff;
              // box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
              .list-tip {
                opacity: 1;
                color: #237dff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
